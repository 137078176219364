<template>
  <v-app theme="dark">
    <v-main class="hero1">
      <Navbar />
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'App',
  components: {
    Navbar
  },
  data: () => ({
    //
  }),
  mounted() {
    this.$store.commit('initializeStore')
  }
}
</script>
<style>
.hero {
  background: url('@/assets/images/fabio.jpg');
  background-size: cover;
  height: 100vh;
}
.hero1 {
  /* background-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0.2), rgba(0,0,0,1)), url('@/assets/images/fabio.jpg');
  background-size: cover;
  height: 100vh; */
  /*background:linear-gradient(296.48deg, #00333333 7.92%, #333 29.44%, #000 79.17%);*/
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
}
</style>
